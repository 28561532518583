import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import './block_problems.css';

const BlockProblems = ({data, trID}) => {
    const { t, i18n} = useTranslation();

    return (
        data &&
        <div className="section__container">
            <div className="training__problems__section">
                <div className="training__problems__content">
                    <div className="content__head">
                        <div className="content_title section__title">{data.block_title}</div>
                        <div className="content_title section__desc">{data.block_desc}</div>
                    </div>
                    <div className="content__data">
                        <div className="container__bullets">
                            <div className="bullets__items">
                                {data.items?.map((item: any) => (
                                    <div key={item.item_sort} className="bullets__item">
                                        <div className="item__body">
                                            <div className="item__icon">
                                                <div className="icon__data"><span></span></div>
                                            </div>
                                            <div className="item__text">
                                                <div className="item__text__title">{item.item_title}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlockProblems;