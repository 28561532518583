import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import BlockCards from '../BlockCards/block_cards';
import './block_trainings.css'

const TrainingsList = () => {
    const { t, i18n} = useTranslation();
    const [count, setCount] = useState(0);
    const [data, setData] = useState([])
    const [hasMore, setMore] = useState(true);

    const fetchAPI = async () => {
        const req = await fetch(
            `${process.env.REACT_APP_API_URL}/trainings/prods/list`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({trCount: count}),
            }).catch((err) => ('Error', console.error(err)));

        const res = await req.json();

        if (!res || res.status.result === false) {
            setMore(false)
        } else {
            const loadData = res.status.resultDATA.trData;
            setData([...data, ...loadData])
        }

    };

    useEffect(() => {
        fetchAPI();
    }, [count]);

    const loadMore = () => {
        setCount(count + 10);
    }

    return (
        <>
            <div className="block__card__items">
                <BlockCards data={data}/>
            </div>
            <div style={{ display: hasMore ? "block" : "none" }} id="loadmore" className="block__load__more">
                <button type="button" onClick={loadMore}>{t('IndexPage.BlockCards.LoadMore')}</button>
            </div>
        </>
    );
};

export default TrainingsList;