import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import './block_steps.css';

const BlockSteps = ({data, trID}) => {
    const { t, i18n} = useTranslation();

    return (
        data &&
        <div className="section__container">
            <div className="training__steps__section">
                <div className="training__steps__content">
                    <div className="content__head">
                        <div className="content_title section__title">{data.block_title} на {data.block_duration}</div>
                        <div className="content_title section__desc">{data.block_desc}</div>
                    </div>
                    <div className="content__data">
                        <div className="container__steps">
                            <div className="steps__items">
                                {data.items?.map((step: any) => (
                                    <div key={step.item_sort} className="steps__item">
                                        <div className="item__body">
                                            <div className="item__body__head">
                                                <div className="head__details">
                                                    <div className="details__duration">Длительность работы по этапу: {step.item_duration}</div>
                                                </div>
                                                <div className="head__steps">
                                                    <div className="head__steps__icon">
                                                        <div className="icon__data">{step.item_step}</div>
                                                    </div>
                                                    <div className="head__steps__data">
                                                        <div className="steps__data__title">{step.item_title}</div>
                                                        <div className="steps__data__desc">{step.item_desc}</div>
                                                    </div>
                                                </div>
                                                <div className="head__skills">
                                                    <div className="skills__items">
                                                        {step.items_skills?.map((skill: any) => (
                                                            <div key={uuidv4()} className="skill__item">
                                                                <div className="skill__data">
                                                                    <div className="skill__data__name">{skill.skill_name}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlockSteps;