import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';

// base
import BlockHeader from '../components/base/BlockHeader/block_header';
import BlockFooter from '../components/base/BlockFooter/block_footer';

const DocPage = () => {
    const { t, i18n} = useTranslation();
    const { docID } = useParams()

    // получаем данные тренинга
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchAPI = async () => {
            const req = await fetch(`${process.env.REACT_APP_API_URL}/docs/data`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({docID: docID}),
            }).catch((err) => ('Error', console.error(err)));
            const res = await req.json();
            setData(res.status.resultDATA.trDocs);
        };
        fetchAPI();
    }, []);

    return (
        <div className="base__container">
            <div className="base__content">
                <BlockHeader/>
                <div className="docs__page">
                    <div className="docs__title">{data.docs_title}</div>
                    {(data.docs_date || data.docs_changes) &&
                        <div className="docs__date">
                            {(data.docs_date) &&
                                <div className="create__date">Creating a
                                    document: {format(parseISO(data.docs_date), "MM.dd.yyyy HH:mm")}</div>
                            }
                            {(data.docs_changes) &&
                                <div className="update__date">The latest
                                    version: {format(parseISO(data.docs_changes), "MM.dd.yyyy HH:mm")}</div>
                            }
                        </div>
                    }
                    <div className="docs__text" dangerouslySetInnerHTML={{__html: data.docs_body}}/>
                </div>
                <BlockFooter/>
            </div>
        </div>
    );
};

export default DocPage;