import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import './block_footer.css';

const Footer = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="page__footer">
            <div className="footer__container">
                <div className="footer__items">
                    <div className="footer__navigate footer__item"></div>
                    <div className="footer__info footer__item">
                        <div className="footer__info__container">
                            <div className="footer__info__logo">
                                <svg xmlns="http://www.w3.org/2000/svg" width="210" height="30" viewBox='0 0 210 20'>
                                    <text className="footer__logo__cls" id={t('Footer.Logo.name')}
                                          transform="translate(3.441 23.005) scale(0.446 0.453)">{t('Footer.Logo.name')}</text>
                                </svg>
                                <div className="footer__info__logo__date">2018-2024г</div>
                            </div>
                            <div className="footer__info__docs">
                                <div className="footer__info__docs__link">
                                    <ul>
                                        <li><a href={`/docs/legal/policy`}>Privacy Policy</a></li>
                                        <li><a href={`/docs/legal/terms`}>Terms & Conditions</a></li>
                                        <li><a href={`/docs/legal/contacts`}>Contacts</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;