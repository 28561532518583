import {ChangeEvent} from 'react';
import { useTranslation } from 'react-i18next';
import './lang_switcher.css';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    // changeLanguage
    const changeLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
        const lng = e.target.value;
        i18n.changeLanguage(lng);
    };

    // locales
    const locales = {
        ru: 'Rus',
        en: 'Eng',
    };

    return (
        <div className="select__dropdown">
            <select
                value={i18n.resolvedLanguage}
                onChange={changeLanguage}
            >
                {Object.entries(locales).map(([code, name]) => (
                    <option value={code} key={code}>
                        {name}
                    </option>
                ))}
            </select>
        </div>
    );
};
export default LanguageSwitcher;