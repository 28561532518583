import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import './block_bullets.css';

const BlockBullets = ({data}) => {
    const { t, i18n} = useTranslation();

    return (
        data &&
        <div className="head__section__bullets">
            <div className="section__bullets__items">
                {data.items?.map((item: any) => (
                    <div key={item.item_sort} className="section__bullets__item">
                        <div className="bullet__count">{item.item_count}</div>
                        <div className="bullet__desc">{item.item_desc}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BlockBullets;