import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

const ShortsService = () => {
    const { linkID } = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAPI = async () => {
            const req = await fetch(`${process.env.REACT_APP_API_URL}/shorts/link`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({linkID: linkID}),
            }).catch((err) => ('Error', console.error(err)));
            const res = await req.json();
            console.log(res.status.resultDATA.fullURL)

            if (res.status.result === true && res.status.resultDATA.fullURL) {
                window.location.replace(res.status.resultDATA.fullURL)
            } else {
                window.location.replace(process.env.REACT_APP_FRONT_URL)
            }
            // navigate("../success", { replace: true });
        };
        fetchAPI();
    }, []);

};

export default ShortsService;