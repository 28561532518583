import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// base
import BlockHeader from '../components/base/BlockHeader/block_header';
import BlockFooter from '../components/base/BlockFooter/block_footer';

// training_page
import BlockHead from '../components/TrainingPage/BlockHead/block_head';
import BlockAbout from '../components/TrainingPage/BlockAbout/block_about';
import BlockForm from '../components/TrainingPage/BlockForm/block_form';
import BlockProblems from '../components/TrainingPage/BlockProblems/block_problems';
import BlockForwho from '../components/TrainingPage/BlockForwho/block_forwho';
import BlockSteps from '../components/TrainingPage/BlockSteps/block_steps';
import BlockAuthor from '../components/TrainingPage/BlockAuthor/block_author';
import BlockVideo from '../components/TrainingPage/BlockVideo/block_video';

const TrainingPage = () => {
    const { t, i18n} = useTranslation();
    const { trID } = useParams()

    // получаем данные тренинга
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchAPI = async () => {
            const req = await fetch(`${process.env.REACT_APP_API_URL}/trainings/prods/data`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({trID: trID}),
            }).catch((err) => ('Error', console.error(err)));
            const res = await req.json();
            setData(res.status.resultDATA);
        };
        fetchAPI();
    }, []);

    return (
        <div className="base__container">
            <div className="base__content">
                <BlockHeader/>
                <BlockHead data={data?.trData} cats={data?.trCats} discount={data?.trDiscount} />
                <BlockAbout data={data.trData?.tr_block_about} trID={data.trData?.tr_id}/>
                <BlockVideo data={data.trData?.tr_block_video} trURL={data.trData?.tr_url}/>
                <BlockForm data={data.trData?.tr_block_form_top} trID={data.trData?.tr_id} prodID={data.trData?.tr_bills_prod_id}/>
                <BlockProblems data={data.trData?.tr_block_problems}/>
                <BlockForwho data={data.trData?.tr_block_forwho}/>
                <BlockSteps data={data.trData?.tr_block_steps}/>
                <BlockForm data={data.trData?.tr_block_form_bottom} trID={data.trData?.tr_id} prodID={data.trData?.tr_bills_prod_id}/>
                <BlockAuthor/>
                <BlockFooter/>
            </div>
        </div>
    );
};

export default TrainingPage;