import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import './block_video.css';

import Plyr from "plyr-react"
import "plyr-react/plyr.css"

const BlockVideo = ({data, trURL}) => {
    const { t, i18n} = useTranslation();

    const mediaData = {
        'sale_2160': `${process.env.REACT_APP_MEDIA_CDN}/tr-sales/${trURL}/sale_2160.mp4`,
        'sale_1440': `${process.env.REACT_APP_MEDIA_CDN}/tr-sales/${trURL}/sale_1440.mp4`,
        'sale_1080': `${process.env.REACT_APP_MEDIA_CDN}/tr-sales/${trURL}/sale_1080.mp4`,
        'sale_720': `${process.env.REACT_APP_MEDIA_CDN}/tr-sales/${trURL}/sale_720.mp4`,
        'sale_480': `${process.env.REACT_APP_MEDIA_CDN}/tr-sales/${trURL}/sale_480.mp4`,
    }

    return (
        data &&
        <div className="section__container">
            <div className="training__video__section" id="play__video">
                <div className="training__video__content">
                    <div className="content__head">
                        <div className="content_title section__title">{data.block_title}</div>
                        <div className="content_title section__desc">{data.block_desc}</div>
                    </div>
                    <div className="content__data">
                        <div className="block__video">
                            <div className="video__player">
                                <div className="player__iframe">
                                    <Plyr
                                        source={{
                                            type: "video",
                                            sources: [
                                                {
                                                    size: 2160,
                                                    src: `${mediaData.sale_2160}`,
                                                    type: 'video/mp4'
                                                },
                                                {
                                                    size: 1440,
                                                    src: `${mediaData.sale_1440}`,
                                                    type: 'video/mp4'
                                                },
                                                {
                                                    size: 1080,
                                                    src: `${mediaData.sale_1080}`,
                                                    type: 'video/mp4'
                                                },
                                                {
                                                    size: 720,
                                                    src: `${mediaData.sale_720}`,
                                                    type: 'video/mp4'
                                                },
                                                {
                                                    size: 480,
                                                    src: `${mediaData.sale_480}`,
                                                    type: 'video/mp4'
                                                },
                                            ],
                                        }}
                                        options={{
                                            quality: {default: 1080, options: [2160, 1440, 1080, 720, 480]},
                                            controls: [
                                                'play-large',
                                                'mute',
                                                'volume',
                                                'fullscreen',
                                                'settings',
                                            ],
                                            hideControls: false,
                                            settings: ['quality'],
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="video__info">
                                <div className="video__info__title"></div>
                                <div className="video__info__desc"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlockVideo;