import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import './block_author.css';

const BlockAuthor = ({data, trID}) => {
    const { t, i18n} = useTranslation();

    return (
        <div className="section__container">
            <div className="training__author__section">
                <div className="training__author__content">
                    <div className="content__head">
                        <div className="content_title section__title">Автор методики</div>
                        <div className="content_title section__desc">Евгений Сарапулов психолог с опытом работы более 30
                            лет. Ведущий психологического ток-шоу День добрый на радиостанциях в разных странах и онлайн
                            трансляций в соцсетях.
                        </div>
                    </div>
                    <div className="content__data">
                        <div className="block__author">
                            <div className="block__photo">
                                {/*<Image src={Logo} width="350" height="350" alt="Евгений Срапулов"/>*/}
                            </div>
                            <div className="block__text">
                                <p>Евгений 12 лет работал преподавателем психологии в университете, где заведовал
                                    кафедрой психологии. Диплом социального психолога по специализации «практическая
                                    психология». С 2004 г по настоящее время ведет прямые эфиры на радиостанциях,
                                    которые транслируются в разных странах, а также онлайн трансляции в социальных
                                    сетях.</p>
                                <p>Работал спортивным психологом в команде биатлонистов. Многолетний опыт работы с
                                    онкобольными. Занимался кризисной, экстремальной психологией – помогал пострадавшим
                                    от терактов, стихийных бедствий.</p>
                                <p>Имеет ученую степень по философии. Евгений женат и у него четверо детей. С 1996 года
                                    разрабатывает практические курсы по психологии отношений и саморазвитию.</p>
                                <p>Девиз Евгения «Не сдавайся за пять минут до чуда» и «Учись смотреть реальности в лицо
                                    так, чтобы не терять веру в победу».</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlockAuthor;