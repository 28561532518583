import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import LangSwitcher from '../LangSwitcher/lang_switcher';
import './block_header.css';

const Header = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="block__header">
            <div className="header__container">
                <div className="header__items">
                    <div className="header__logo header__item">
                        <div className="logo__items">
                            <div className="logo__item item__logo">
                                <a href="/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="210" height="30" viewBox={isMobile === 'phone' ? '0 0 210 20' : '0 0 210 30'}>
                                        <text className="header__logo__cls" id={t("Header.Logo.name")} transform="translate(3.441 23.005) scale(0.446 0.453)">
                                            {t("Header.Logo.name")}
                                        </text>
                                    </svg>
                                    <div className="header__logo__text">{t('Header.Logo.desc')}</div>
                                </a>
                            </div>
                            <div className="logo__item item__lang">
                                <LangSwitcher/>
                            </div>
                        </div>
                    </div>
                    <div className="header__user header__item">
                        <div className="header__user__navigate">
                            <div className="navigate__items">
                                <div className="navigate__item nav__lang"></div>
                                <div className="navigate__item nav__login">
                                    <a className="login" href="/">
                                        {isMobile ? t("Header.Login.Mobile.button") : t("Header.Login.Desktop.button")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;