import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import './block_cats.css';

const BlockCats = ({catID}) => {
    const { t, i18n} = useTranslation();
    const locale = i18n.resolvedLanguage;
    const pathname = useLocation().pathname;

    // получаем список категорий
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchAPI = async () => {
            const req = await fetch(`${process.env.REACT_APP_API_URL}/trainings/cats/list`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
            }).catch((err) => ('Error', console.error(err)));
            const res = await req.json();
            setData(res.status.resultDATA.trCats);
        };
        fetchAPI();
    }, []);


    return (
        <>
            {(pathname === `/trainings/cat/${catID}`) &&
                <div className="block__cards__list__head head__page__cats">
                    <div className="block__card__head__title">
                        {data?.map((item: any) => (
                            <div key={uuidv4()}>
                                {(item.cat_url === catID) &&
                                    <> {t('CatsPage.Head.name')} — <span>{locale === 'ru' ? item.cat_name : item.cat_en_name}</span></>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            }

            <div className="block__cats">
                <div className={pathname === '/' ? 'block__cats__list page__all' : 'block__cats__list page__cats'}>
                    <div className="block__cats__items">
                        <ul className={pathname === '/' ? 'cats__items items__page__all' : 'cats__items items__page__cats'}>

                            {data?.map((item) => (
                                isMobile ? (
                                    item.cat_level === 1 &&
                                    <li key={uuidv4()}
                                        className={pathname === `/trainings/cat/${item.cat_url}` ? 'active cats__item' : 'cats__item'}>
                                        <a href={`/trainings/cat/${item.cat_url}`}>
                                            {locale === 'ru' ? item.cat_name : item.cat_en_name}
                                        </a>
                                    </li>
                                ) : (
                                    <li key={uuidv4()}
                                        className={pathname === `/trainings/cat/${item.cat_url}` ? 'active cats__item' : 'cats__item'}>
                                        <a href={`/trainings/cat/${item.cat_url}`}>
                                            {locale === 'ru' ? item.cat_name : item.cat_en_name}
                                        </a>
                                    </li>
                                )
                            ))}

                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlockCats;