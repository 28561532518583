import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

// base
import BlockHeader from '../components/base/BlockHeader/block_header';
import BlockFooter from '../components/base/BlockFooter/block_footer';
import BlockCats from '../components/base/BlockCats/block_cats';

// front
import BlockTyped from '../components/FrontPage/BlockTyped/block_typed';
import BlockTrainings from '../components/FrontPage/BlockTrainings/block_trainings';
import BlockDiscounts from '../components/FrontPage/BlockDiscounts/block_discounts';


const FrontPage = () => {
    const { t, i18n} = useTranslation();

    return (
        <div className="base__container">
            <div className="base__content">
                <BlockHeader/>

                <section className="start__screen">
                    <div className="screen__block">
                        <div className="block__container">
                            <div className="screen__cnt">
                                <BlockTyped/>
                                <BlockCats/>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="block__cards">
                    {/*<div className="block__cards__list block__first">*/}
                    {/*    <div className="block__container">*/}
                    {/*        <div className="block__cards__list__head head__page__all">*/}
                    {/*            <div className="block__card__head__title">{t('IndexPage.BlockDiscount.Head.title')}</div>*/}
                    {/*            <div className="block__card__head__link">*/}
                    {/*                <a href={'/'}>{t('IndexPage.BlockDiscount.Head.button')}</a></div>*/}
                    {/*        </div>*/}
                    {/*        <div className="block__card__items">*/}
                    {/*            <BlockDiscounts/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="block__cards__list">
                        <div className="block__container">
                            <div className="block__cards__list__head head__page__all">
                                <div className="block__card__head__title">{t('IndexPage.BlockCards.Head.title')}</div>
                            </div>
                            <BlockTrainings/>
                        </div>
                    </div>
                </div>



                <BlockFooter/>
            </div>
        </div>
    );
};

export default FrontPage;