import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import './block_form.css';

const BlockForm = ({data, trID, prodID}) => {
    const { t, i18n} = useTranslation();
    const locale = i18n.resolvedLanguage;

    return (
        data &&
        <div className="section__container">
            <div className="training__form__section">
                <div className="training__form__content">
                    <div className="content__head">
                        <div className="content__title section__title">{data.block_title}</div>
                        <div className="content__desc section__desc">{data.block_desc}</div>
                    </div>
                    <div className="content__data">
                        <div className="block__form">
                            <div className="block__form__info">
                                <div className="form__info__title">{data.form_title}</div>
                                <div className="form__info__desc">{data.form_desc}</div>
                            </div>
                            <div className="block__form__lead">

                                <form action={`${process.env.REACT_APP_TRUST_FORM_URL}${prodID}`} method="post" id="lead__form">

                                    <div className="form__input">
                                        <input type="text" name="billing_name" placeholder={t('TrainingPage.Form.Form.name')} required/>
                                    </div>
                                    <div className="form__input">
                                        <input type="email" name="billing_email" placeholder={t('TrainingPage.Form.Form.email')} required/>
                                    </div>
                                    <div className="form__button">
                                        <button type="submit">{t('TrainingPage.Form.Button.name')}</button>
                                    </div>

                                </form>
                            </div>
                            <div className="block__form__confirm">
                                {t('TrainingPage.Form.Confirm.confirm_text')}
                                <a href={`/docs/legal/policy`} target="_blank">{locale === 'ru' ? 'Политикой конфиденциальности' : 'Privacy Policy' }</a>
                                {locale === 'ru' ? ' и ' : ' and the '}
                                <a href={`/docs/legal/terms`} target="_blank">{locale === 'ru' ? 'Правилами и Условиями' : 'Terms and Conditions' }</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlockForm;