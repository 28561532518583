import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// lng_common
import lng_ru from "./locales/ru/translation.json";
import lng_en from "./locales/en/translation.json";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            ru: {
                common: lng_ru,
            },
            en: {
                common: lng_en,
            },
        },
        ns: ["common"],
        // backend: {
        //     loadPath: '../src/config/locales/{{lng}}/{{ns}}.json',
        // },
        debug: false,
        detection: {
            lookupCookie: 'sp_lng',
            lookupQuerystring: 'sp_lng',
            order: ['querystring', 'cookie'],
            caches: ['cookie']
        },
        saveMissing: true,
        fallbackLng: 'en',
        preload: ['en', 'ru']
    });
export default i18n;