import React, { useState, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// base
import BlockHeader from '../components/base/BlockHeader/block_header';
import BlockFooter from '../components/base/BlockFooter/block_footer';
import BlockCats from '../components/base/BlockCats/block_cats';

// cat_page
import BlockTrainings from '../components/TrainingCatPage/BlockTrainings/block_trainings';

const TrainingCatPage = () => {
    const { t, i18n} = useTranslation();
    const locale = i18n.resolvedLanguage;
    const pathname = useLocation().pathname;

    const { catID } = useParams()

    return (
        <div className="base__container">
            <div className="base__content">
                <BlockHeader/>

                <div className="block__cards__list">
                    <BlockCats catID={catID}/>
                    <BlockTrainings catID={catID}/>
                </div>

                <BlockFooter/>
            </div>
        </div>
    );
};

export default TrainingCatPage;