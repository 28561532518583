import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import './block_cards.css';

const TrainingsBlock = ({data}) => {
    const { t, i18n} = useTranslation();
    const locale = i18n.resolvedLanguage;
    const pathname = useLocation().pathname;

    const trimText = /(<([^>]+)>)/gi;

    return (
        <>
            {data?.map((item) => {

                if (item.tr_block_id === 1)
                    return (
                        <div key={item.id} className="block__card__item card__item__block-1">
                            <a href={`/trainings/${item.tr_url}`}>
                                <div className="card__item__container">
                                    <div className="card__item__body">
                                        <div className="card__img">
                                            <div className="card__img__src"
                                                 style={{
                                                     backgroundImage: isMobile? `url(${process.env.REACT_APP_STATIC_CDN}/uploads/${item.id}/420x500/${item.tr_image}_420x500.webp)` : `url(${process.env.REACT_APP_STATIC_CDN}/uploads/${item.id}/620x400/${item.tr_image}_620x400.webp)`
                                                 }}>
                                            </div>
                                        </div>
                                        <div className="card__top">
                                            {(item.tr_prod_discount_mode) &&
                                                <div className="card__item__discount">
                                                    {
                                                        (item.tr_prod_discount_mode && item.tr_prod_discount_mode === 1) ?
                                                            (item.tr_prod_discount ? (`-${item.tr_prod_discount}%`) : '')
                                                            :
                                                            (locale === 'ru' && item.tr_prod_discount ? (`${t('IndexPage.BlockDiscount.Price.Discount.name')} ${item.tr_prod_discount}₽`) : locale === 'en' && item.tr_prod_discount ? (`$${item.tr_prod_price_usd} ${t('IndexPage.BlockDiscount.Price.Discount.name')}`) : '')
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className="card__bottom">
                                            <div className="card__item__count">{item.tr_edu_time} / {item.tr_stages}</div>

                                            <div className="card__item__text">
                                                <div className="card__item__title">{item.tr_title}</div>
                                                <div className="card__item__desc">{item.tr_short_desc ? item.tr_short_desc.replace(trimText, '') : ''}</div>
                                            </div>

                                            {(item.tr_prod_price_src) &&
                                                <div className="card__item__price">
                                                    <div className="price__sub">
                                                        {(locale === 'ru' && item.tr_prod_price_src ? (`${item.tr_prod_price_src}₽`) : locale === 'en' && item.tr_prod_price_usd_src ? (`${t('IndexPage.BlockCards.Price.price_full')} $${item.tr_prod_price_usd_src}`) : '')}
                                                    </div>
                                                    {/*<div className="price__full">от 3 490 ₽/мес по подписке</div>*/}
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    );
                if (item.tr_block_id === 2)
                    return (
                        <div key={item.id} className="block__card__item card__item__block-2">
                            <a href={`/trainings/${item.tr_url}`}>
                                <div className="card__item__container">
                                    <div className="card__item__body">
                                        <div className="card__img">
                                            <div className="card__img__src"
                                                 style={{
                                                     backgroundImage: isMobile ? `url(${process.env.REACT_APP_STATIC_CDN}/uploads/${item.id}/380x450/${item.tr_image}_380x450.webp)` : `url(${process.env.REACT_APP_STATIC_CDN}/uploads/${item.id}/300x400/${item.tr_image}_300x400.webp)`
                                                 }}>
                                            </div>
                                        </div>
                                        <div className="card__top">

                                            {(item.tr_prod_discount_mode) &&
                                                <div className="card__item__discount">
                                                    {
                                                        (item.tr_prod_discount_mode && item.tr_prod_discount_mode === 1) ?
                                                            (item.tr_prod_discount ? (`-${item.tr_prod_discount}%`) : '')
                                                            :
                                                            (locale === 'ru' && item.tr_prod_discount ? (`${t('IndexPage.BlockDiscount.Price.Discount.name')} ${item.tr_prod_discount}₽`) : locale === 'en' && item.tr_prod_discount ? (`$${item.tr_prod_price_usd} ${t('IndexPage.BlockDiscount.Price.Discount.name')}`) : '')
                                                    }
                                                </div>
                                            }

                                            {/*<div className="card__item__count">{item.tr_edu_time} / {item.tr_stages}</div>*/}
                                            {/*{(item.tr_prod_price_src) &&*/}
                                            {/*    <div className="card__item__price">*/}
                                            {/*        <div className="price__sub">*/}
                                            {/*            {(locale === 'ru' && item.tr_prod_price_src ? (`${item.tr_prod_price_src}₽`) : locale === 'en' && item.tr_prod_price_usd_src ? (`${t('BlockCards.Price.price_full')} $${item.tr_prod_price_usd_src}`) : '')}*/}
                                            {/*        </div>*/}
                                            {/*        /!*<div className="price__full">от 3 490 ₽/мес по подписке</div>*!/*/}
                                            {/*    </div>*/}
                                            {/*}*/}
                                        </div>
                                        <div className="card__bottom">
                                            <div className="card__item__count">{item.tr_edu_time} / {item.tr_stages}</div>

                                            <div className="card__item__text">
                                                <div className="card__item__title">{item.tr_title}</div>
                                                <div className="card__item__desc">{item.tr_short_desc ? item.tr_short_desc.replace(trimText, '') : ''}</div>
                                            </div>

                                            {(item.tr_prod_price_src) &&
                                                <div className="card__item__price">
                                                    <div className="price__sub">
                                                        {(locale === 'ru' && item.tr_prod_price_src ? (`${item.tr_prod_price_src}₽`) : locale === 'en' && item.tr_prod_price_usd_src ? (`${t('IndexPage.BlockCards.Price.price_full')} $${item.tr_prod_price_usd_src}`) : '')}
                                                    </div>
                                                    {/*<div className="price__full">от 3 490 ₽/мес по подписке</div>*/}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    );
                if (item.tr_block_id === 3)
                    return (
                        <div key={item.id} className="block__card__item card__item__block-3">
                            <a href={`/trainings/${item.tr_url}`}>
                                <div className="card__item__container">
                                    <div className="card__item__head">
                                        <div className="card__img">
                                            <div className="card__img__src"
                                                 style={{
                                                     backgroundImage: `url(${process.env.REACT_APP_STATIC_CDN}/uploads/${item.id}/300x185/${item.tr_image}_300x185.webp)`
                                                 }}>
                                            </div>
                                        </div>
                                        <div className="card__top">
                                            <div className="card__item__count">{item.tr_edu_time} / {item.tr_stages}</div>
                                        </div>
                                    </div>

                                    <div className="card__item__body">
                                        <div className="card__item__count">{item.tr_edu_time} / {item.tr_stages}</div>
                                        <div className="card__item__title">{item.tr_title}</div>
                                        <div className="card__item__desc">
                                            {item.tr_short_desc ? item.tr_short_desc.replace(trimText, '') : ''}
                                        </div>
                                        {/*{ (item.tr_prod_price_src) &&*/}
                                        {/*    <div className="card__item__price">*/}
                                        {/*        <div className="price__sub">Стоимость {item.tr_prod_price_src ? item.tr_prod_price_src : ''}₽</div>*/}
                                        {/*        <div className="price__full">от 3 490 ₽/мес по подписке</div>*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                    </div>
                                </div>
                            </a>
                        </div>
                    );
                if (item.tr_block_id === 4)
                    return (
                        <div key={item.id} className="block__card__item card__item__block-4">
                            <a href={`/${locale}/trainings/${item.tr_url}`}>
                                <div className="card__item__container">
                                    <div className="card__item__body">
                                        <div className="card__top">
                                            {/*<div className="card__item__count">{item.tr_edu_time} / {item.tr_stages}</div>*/}
                                        </div>
                                        <div className="card__bottom">
                                            <div className="card__item__count">{item.tr_edu_time} / {item.tr_stages}</div>

                                            <div className="card__item__text">
                                                <div className="card__item__title">{item.tr_title}</div>
                                                <div className="card__item__desc">{item.tr_short_desc ? item.tr_short_desc.replace(trimText, '') : ''}</div>
                                            </div>

                                            {(item.tr_prod_price_src) &&
                                                <div className="card__item__price">
                                                    <div className="price__sub">
                                                        {(locale === 'ru' && item.tr_prod_price_src ? (`${item.tr_prod_price_src}₽`) : locale === 'en' && item.tr_prod_price_usd_src ? (`${t('IndexPage.BlockCards.Price.price_full')} $${item.tr_prod_price_usd_src}`) : '')}
                                                    </div>
                                                    {/*<div className="price__full">от 3 490 ₽/мес по подписке</div>*/}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    );
            })}

        </>
    );
}

export default TrainingsBlock;