import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import './block_about.css';

const BlockAbout = ({data, trID}) => {
    const { t, i18n} = useTranslation();

    return (
        data &&
        <div className="section__container">
            <div className="training__about__section">
                <div className="training__about__content">
                    <div className="content__head">
                        <div className="content_title section__title">{data.block_title}</div>
                        <div className="content_title section__desc">{data.block_desc}</div>
                    </div>
                    <div className="content__data">
                        <div className="block__about">
                            <div className="block__text" dangerouslySetInnerHTML={{__html: data.about_desc}}/>
                            <div className="block__photo"
                                 style={{
                                     backgroundImage: `url(${process.env.REACT_APP_STATIC_CDN}/uploads/${trID}/tr_block_about/350x350/${data.block_img}_350x350.webp)`
                                 }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlockAbout;