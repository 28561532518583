import {React, useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { isMobile } from 'react-device-detect';

// pages
import FrontPage from "./pages/FrontPage";
import TrainingPage from "./pages/TrainingPage";
import TrainingCatPage from "./pages/TrainingCatPage";
import DocPage from "./pages/DocPage";
import ShortsLink from "./services/shorts/shorts.service";

import './App.css';

function App() {

    useEffect(() => {
        isMobile ? document.body.classList.add("mb") : document.body.classList.add("dp");
    }, []);

  return (
      <div>
          <Router>
            <Routes>
              <Route path='/' element={<FrontPage />} />
              <Route path='/trainings/:trID' element={<TrainingPage />} />
              <Route path='/trainings/cat/:catID' element={<TrainingCatPage />} />
              <Route path='/docs/legal/:docID' element={<DocPage />} />
              <Route path='/lnk/:linkID' element={<ShortsLink />} />
            </Routes>
          </Router>
      </div>
  );
}

export default App;
