import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import './block_head.css';

import BlockBullets from '../BlockBullets/block_bullets';

const BlockHead = ({data, cats, discount}) => {
    const { t, i18n} = useTranslation();
    const locale = i18n.resolvedLanguage;

    // Блок текст по центру
    if (data?.tr_page_id === 1) {
        return (
            <div className="training__head__section section__container">
                <div className="training__head__content">
                    <div className="head__section__top__1">
                        <div className="section__top__img">
                            <div className="section__top__img__src"
                                 style={{
                                     backgroundImage: `url(${process.env.REACT_APP_STATIC_CDN}/uploads/${data.id}/1300x700/${data.tr_image}_1300x700.webp)`
                                 }}>
                            </div>
                        </div>
                        <div className="section__top__text top__upper">
                            <div className="section__top__cat">
                                {cats?.map((item: any, i: number) => (
                                    (isMobile) ? (i < 2) && <span key={uuidv4()}>{locale === 'ru' ? item.cat_name : item.cat_en_name}</span> :
                                        <span key={uuidv4()}>{locale === 'ru' ? item.cat_name : item.cat_en_name}</span>
                                ))}
                            </div>
                            <div className="section__top__title">{data.tr_title}</div>
                            <div className="section__top__desc" dangerouslySetInnerHTML={{__html: data.tr_desc}}/>
                        </div>
                        <div className="section__top__start top__upper">
                            <div className="section__top__start__btn"><a
                                href="#play__video">{t('TrainingPage.Head.Button.name')}</a></div>
                        </div>
                    </div>
                    <BlockBullets data={data?.tr_block_info}/>
                </div>
            </div>
        )
    }

    // Блок текст слева
    if (data?.tr_page_id === 2) {
        return (
            <div className="training__head__section section__container">
                <div className="training__head__content">
                    <div className="head__section__top__2">
                        <div className="section__top__img">
                            <div className="section__top__img__src"
                                 style={{
                                     backgroundImage: `url(${process.env.REACT_APP_STATIC_CDN}/uploads/${data.id}/1300x700/${data.tr_image}_1300x700.webp)`
                                 }}>
                            </div>
                        </div>
                        <div className="section__top__text top__upper">
                            {discount &&
                                <div className="upper__hd">
                                    <div className="cn__hd__discount">
                                        <span>
                                            {discount && discount.discount_mode === 1 ?
                                                (locale === 'ru') ? (`${t('TrainingPage.Head.Discount.name')} ${discount.discount_sum}%`) : (`${discount.discount_sum}% ${t('TrainingPage.Head.Discount.name')}`)
                                                :
                                                (locale === 'ru') ? (`${t('TrainingPage.Head.Discount.name')} ${discount.discount_sum}₽`) : (`$${discount.discount_sum} ${t('TrainingPage.Head.Discount.name')}`)
                                            }
                                        </span>
                                    </div>
                                </div>
                            }
                            <div className="upper__cn">
                                <div className="upper__cn__text">
                                    {cats &&
                                        <div className="section__top__cats">
                                            <div className="upper__cn__cats">
                                                {cats?.map((item: any, i: number) => (
                                                    (isMobile) ? (i < 2) && <span key={uuidv4()}>{locale === 'ru' ? item.cat_name : item.cat_en_name}</span> : <span key={uuidv4()}>{locale === 'ru' ? item.cat_name : item.cat_en_name}</span>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    <div className="section__top__title">{data.tr_title}</div>
                                    <div className="section__top__desc"
                                         dangerouslySetInnerHTML={{__html: data.tr_desc}}/>
                                    <div className="section__top__button">
                                        <a href="#play__video">{t('TrainingPage.Head.Button.name')}</a>
                                    </div>
                                </div>
                                {!isMobile &&
                                    <div className="upper__cn__bullets">
                                        <div className="cn__bullets__items">
                                            {data.tr_block_forwho.items?.map((item: any, i: number) => (
                                                (i < 4) &&
                                                <div key={uuidv4()} className="cn__bullets__item">
                                                    <div className="bullet__item__count">{item.item_title}</div>
                                                    <div className="bullet__item__desc">{item.item_desc}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <BlockBullets data={data?.tr_block_info}/>
                </div>
            </div>
        )
    }

    // Блок текст слева + bullets
    if (data?.tr_page_id === 3) {
        return (
            <div className="training__head__section section__container">
                <div className="training__head__content">
                    <div className="head__section__top__3">
                        <div className="section__top__img">
                            <div className="section__top__img__src"
                                 style={{
                                     backgroundImage: `url(${process.env.REACT_APP_STATIC_CDN}/uploads/${data.id}/1300x700/${data.tr_image}_1300x700.webp)`
                                 }}>
                            </div>
                        </div>
                        <div className="section__top__text top__upper">
                            <div className="upper__hd">
                                {discount &&
                                    <div className="cn__hd__discount">
                                        <span>
                                            {discount && discount.discount_mode === 1 ?
                                                (locale === 'ru') ? (`${t('TrainingPage.Head.Discount.name')} ${discount.discount_sum}%`) : (`${discount.discount_sum}% ${t('TrainingPage.Head.Discount.name')}`)
                                                :
                                                (locale === 'ru') ? (`${t('TrainingPage.Head.Discount.name')} ${discount.discount_sum}₽`) : (`$${discount.discount_sum} ${t('TrainingPage.Head.Discount.name')}`)
                                            }
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className="upper__cn">
                                <div className="upper__cn__text">
                                    {cats &&
                                        <div className="section__top__cats">
                                            <div className="upper__cn__cats">
                                                {cats?.map((item: any, i: number) => (
                                                    (isMobile) ? (i < 2) && <span key={uuidv4()}>{locale === 'ru' ? item.cat_name : item.cat_en_name}</span> : <span key={uuidv4()}>{locale === 'ru' ? item.cat_name : item.cat_en_name}</span>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    <div className="section__top__title">{data.tr_title}</div>
                                    <div className="section__top__desc"
                                         dangerouslySetInnerHTML={{__html: data.tr_desc}}/>
                                    <div className="section__top__button">
                                        <a href="#play__video">{t('TrainingPage.Head.Button.name')}</a>
                                    </div>
                                </div>
                                {!isMobile &&
                                    <div className="upper__cn__bullets">
                                        <div className="cn__bullets__items">
                                            {data.tr_block_info.items?.map((item: any) => (
                                                <div key={uuidv4()} className="cn__bullets__item">
                                                    <div className="bullet__item__count">{item.item_count}</div>
                                                    <div className="bullet__item__desc">{item.item_desc}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    {isMobile &&
                        <BlockBullets data={data?.tr_block_info}/>
                    }
                </div>
            </div>
        )
    }

}

export default BlockHead;